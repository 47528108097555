import styled from '@emotion/styled';

export const Container = styled.div`
  position: relative;
`;

export const Overlay = styled.div`
  background-color: rgba(0,0,0,0.2);
  position: absolute;
  z-index: 2;
  width: 100%;
  height: 100%;
  .int-bg-image {
    position: absolute;
  }
`;

export const Content = styled.div`
  color: #fff !important;
  text-align: center;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  .int-addr {
    white-space: nowrap;
  }
  > div {
    width: 100%;
    margin: 10px auto;
    svg {
      margin-right: 10px;
      vertical-align: text-top;
    }
    .int-title {
      font-size: 1.5rem;
    }
    a {
      margin: 10px 20px;
      text-decoration: none;
    }
    p {
      margin-bottom: 0;
      text-shadow: #5a5a5a 0 0 30px;
      line-height: 1.8rem;
    };
  }
`;

export const Background = styled.div`
  width: 100%;
  height: 400px;
  @media (max-width: 360px) {
    height: 500px;
  }
  background-image: url("${props => props.bgImage}");
  background-repeat: no-repeat;
  background-size: cover;
`;