import React                       from 'react';
import Img                         from 'gatsby-image';
import { MDXProvider as Provider } from '@mdx-js/react';

import Heading     from '../components/text/Heading/Heading';
import Spacer      from '../components/structure/Spacer/Spacer';
import Link        from '../components/elements/Link/Link';
import Wrapper     from '../components/structure/Wrapper/Wrapper';
import CTASection  from '../components/modules/CTASection/CTASection';
import HeaderImage from '../components/media/HeaderImage/HeaderImage';
import HeaderImg   from '../components/media/HeaderImg/HeaderImg';
import Separator   from '../components/structure/Separator/Separator';

const components = {
  h1: Heading,
  hr: Spacer,
  a: Link
};

const shortcodes = {
  Wrapper,
  CTASection,
  Link,
  HeaderImage,
  HeaderImg,
  Heading,
  Img,
  Separator
};

const MDXProvider = ({ children }) => {
  return (
    <Provider components={{ ...components, ...shortcodes }} >
      {children}
    </Provider>
  )
};

export default MDXProvider;