import React                                   from 'react';
import PropTypes                               from 'prop-types';
import { useStaticQuery, graphql }             from 'gatsby';
import { FaPhone, FaEnvelope, FaMapMarkerAlt } from 'react-icons/fa';

import Button      from '@interness/web-core/src/components/elements/Button/Button';
import Link        from '@interness/web-core/src/components/elements/Link/Link';
import PhoneNumber from '@interness/web-core/src/components/text/PhoneNumber/PhoneNumber';

import * as S from './styles';

const CTASection = ({ header, text, mailButtonText, showPhone, showMail, showAddress }) => {
  const data = useStaticQuery(query);
  const address = data.directusCompany.addresses[0];
  const phone = data.directusCompany.numbers.filter(number => number.primary && number.type === 'phone')[0];
  return (
    <S.Container>
      <S.Overlay>
        <S.Content>
          <div>
            <p className='int-title' dangerouslySetInnerHTML={{__html: header}} />
          </div>
          <div>
            {showPhone && <Button as='a' href={`tel:${phone.number}`} primary>
              <FaPhone/>
              <PhoneNumber number={phone.number} callable={false}/>
            </Button>}
            {showMail && <Button primary={!showPhone} as={Link} to='/kontakt'>
              <FaEnvelope/>
              <span>{mailButtonText}</span>
            </Button>}
          </div>
          <div>
            <p>{text}<br/>
              {showAddress &&
              <><FaMapMarkerAlt/> {address.street} {address.number}, <span
                className='int-addr'>{address.zip_code} {address.city}</span></>}
            </p>
          </div>
        </S.Content>
      </S.Overlay>
      <S.Background bgImage={data.backgroundImage.childImageSharp.original.src}/>
    </S.Container>
  )
};

CTASection.propTypes = {
  header: PropTypes.string,
  text: PropTypes.string,
  mailButtonText: PropTypes.string,
  showPhone: PropTypes.bool,
  showMail: PropTypes.bool,
  showAddress: PropTypes.bool,
};

CTASection.defaultProps = {
  header: 'Haben Sie Fragen oder benötigen Sie Hilfe?',
  text: 'Oder besuchen Sie uns persönlich in unserem Ladenlokal:',
  mailButtonText: 'E-Mail',
  showPhone: true,
  showMail: true,
  showAddress: true,
};

const query = graphql`
  query {
    backgroundImage: file(relativePath: {eq: "cta.jpg"}) {
      childImageSharp {
        original {
          src
        }
      }
    }
    directusCompany {
      addresses {
        city
        number
        street
        zip_code
      }
      numbers {
        number
        primary
        type
      }
    }
  }
`;

export default CTASection;
