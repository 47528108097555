import React from 'react';

import SEO         from '../../components/modules/SEO/SEO';
import MDXProvider from '../../providers/MdxProvider';

const MdxDefaultPageTemplate = ({ children, pageContext }) => {
  return (
    <>
      <SEO title={pageContext.frontmatter.title}/>
      <MDXProvider>
        {children}
      </MDXProvider>
    </>
  )
};

export default MdxDefaultPageTemplate;
